.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: var(--background_gray);
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: #e33084;
  font-weight: 800;
}

.label-auth {
  font-size: 14px;
  font-weight: 600;
  color: #51bed0;
}

.boton-login {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--black);
  border-radius: 4px;
  background: var(--black);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}
.boton-login {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--black);
  border-radius: 4px;
  background: var(--black);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}