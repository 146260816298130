/* Estilo para el botón desactivado */
.disabled-button {
  background-color: #ccc; /* Cambia el color de fondo a gris */
  color: #999; /* Cambia el color del texto a gris claro */
  cursor: not-allowed; /* Cambia el cursor del mouse a 'no permitido' */
  pointer-events: none; /* Evita que el botón sea interactivo */
}

.small-input {
  width: 100px; /* Ancho más pequeño */
  height: 30px; /* Altura más pequeña */
  font-size: 14px; /* Tamaño de fuente más pequeño */
  /* Otros estilos personalizados si es necesario */
}

/* Estilo para reducir el espacio entre filas */
table {
  border-collapse: collapse !important; /* Combina los bordes de las celdas para eliminar espacios adicionales */
}

table tbody tr {
  margin-bottom: 5px !important; /* Ajusta la separación vertical entre filas */
}

.main-close2 {
  color: #ff0055;
  border: none;
  border-radius: 5px;
  background-color: white;
}

.main-close-btn {
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #e33084;
  padding: 10px 20px; /* Ajusta el padding para hacer el botón más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto más grande */
}

.main-close2:hover {
  color: white;
  background-color: #e33084;
}

.main-close {
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 20px; /* Ajusta el padding para hacer el botón más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto más grande */
}

.main-close:hover {
  background-color: #e33084;
}
