.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* Esto centra verticalmente en la mitad de la página */
}

.container-fluid h1 {
  font: bold;
  color: #e33084;
  font-size: 5rem;
}

.container-fluid p {
  font: bold;
  color: #51bed0;
  text-align: left;
}


.backgroud {
  margin: 0;
  padding: 0;
  background-image: url("../Images/Fondo.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 80vh;
}

.header {
  background-color: #e33084;
}

.icon {
  width: 1.5rem;
  height: 2rem;
  margin-right: 2rem;
}

.icon:hover {
  color: #51bed0;
}

.test {
  background-color: #e33084;
  color: black;
}

/* En tu archivo Styles.css */
/* En tu archivo Styles.css o en el lugar donde gestionas los estilos */

.parrafo {
  font-size: 1.5rem;
}

.btn-screen-main {
  font-size: 1.5rem;
  padding: 2rem;
  border-radius: 20px;
  background-color: #51bed0;
  color: white;
  border: none;
}

.btn-screen-main:hover {
  background-color: #e33084;
}

/* Estilos del modal */
/* Estilos para el modal de pantalla completa */
.custom-modal {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  /* Habilita el scroll vertical cuando sea necesario */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Estilos para el contenido dentro del modal */
.custom-modal-content {
  width: 100%;
  max-width: 400px;
  /* Ajusta el ancho máximo del contenido según tus necesidades */
  background-color: #fff;
  /* Fondo blanco para el contenido */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}


