
.navbar {
  background-color: #e33084;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 12rem;
  height: 3rem;
  margin-right: 2rem;
}

.user-info {
  padding: 10px;
  text-align: center;
  background-color: #e33084;
  color: #fff;
}


.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
}

.navbar-title {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
  margin-right: 8rem;
}

.nav-menu {
  background-color: #e33084;
  width: 250px;
  height: 100vh;
  display: flex;
  position: absolute;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 350ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

/* Individual navigation items */
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text a:hover {
  background-color: #d06aff; 
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #e33084;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span {
  margin-left: 16px;
}
