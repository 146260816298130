.container-fluid h2 {
  color: #e33084;
}

.container-fluid h5 {
  color: #51bed0;
}

.container-fluid label {
  color: #51bed0;
}


.main-select {
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 20px; /* Ajusta el padding para hacer el select más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto del select más grande */
}

.main-select2 {
  margin-top: 1rem;
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 30px; /* Ajusta el padding para hacer el select más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto del select más grande */
  width: 100%;
}

.main-select:hover {
  background-color: #e33084;
}

.main-close {
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 20px; /* Ajusta el padding para hacer el botón más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto más grande */
}

.main-close:hover {
  background-color: #e33084;
}

.main-close3 {
  color: gray;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  border-bottom: 1px inset gray;
}

.main-close3:hover {
  color: white;
  background-color: gray;
}

.secondary-button {
  border-radius: 5px;
  background-color: #51bed0;
  color: white;
  border: none;
  padding: 10px 20px; /* Ajusta el padding para hacer el botón más grande */
  font-size: 12px;
}

/* Estilos para los botones de hora */
.hora-button {
  background-color: #f0f0f0; /* Color de fondo predeterminado */
  color: #333; /* Color de texto predeterminado */
  border: 1px solid #ccc; /* Borde predeterminado */
  padding: 5px 10px; /* Espaciado interno */
  margin: 5px; /* Margen exterior */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  outline: none; /* Elimina el borde al hacer clic */
  transition: background-color 0.3s, color 0.3s; /* Transición de color suave */
}

/* Estilos para el botón de hora seleccionado */
.hora-button.selected {
  background-color: #e33084; /* Color de fondo cuando está seleccionado */
  color: #fff; /* Color de texto cuando está seleccionado */
  border: 1px solid #e33084; /* Borde cuando está seleccionado */
}

/* Estilos para horarios deshabilitados */
.hora-deshabilitada {
  background-color: #ccc; /* Cambia el fondo a un color gris claro */
  opacity: 0.6; /* Reduce la opacidad para indicar que está deshabilitado */
  cursor: not-allowed; /* Cambia el cursor para indicar que no se puede hacer clic */
  /* Puedes agregar otros estilos según tus preferencias */
}

.margin {
  margin-right: 1rem;
}

/* Estilo para el botón deshabilitado */
.disabled_button_next {
  background-color: #ccc; /* Cambia el color de fondo */
  color: #666; /* Cambia el color del texto */
  cursor: not-allowed; /* Cambia el cursor al estilo "no permitido" */
  /* Otros estilos personalizados según tus preferencias */
}

.option-divider {
  border-top: 1px solid #ccc; /* Color y estilo de la línea */
  margin-top: 5px; /* Espacio entre cada opción */
  padding-top: 5px; /* Espacio entre la línea y el texto */
}

.scrollable-table {
  height: auto;
  color: black;
  max-height: 300px; /* Establece la altura máxima deseada */
  overflow-y: scroll; /* Agrega una barra de desplazamiento vertical cuando el contenido exceda la altura máxima */
  width: 100%; /* Opcional: establece el ancho al 100% para que se ajuste correctamente al contenedor */
}
.table {
  color: black;
}
.opcion-seleccionada {
  background-color: #e33084; /* Cambia el fondo a un color de tu elección */
  color: black;
  font-weight: bold;
}

.selected {
    background-color: #e33084; /* Cambia el fondo a un color gris claro */
    font-weight: bold; /* Puede hacer el texto en negrita */
}
