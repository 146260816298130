.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e33084;
  display: flex; /* Usamos flexbox para centrar el contenido */
  justify-content: center; /* Centramos horizontalmente el contenido */
  align-items: center; /* Centramos verticalmente el contenido */
}

.footer p {
  color: black; /* Cambia el color del texto a negro */
}
