$blue: rgb(28, 130, 144);
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: #e33084;
$secondary: rgb(28, 130, 144);
$success: rgb(28, 130, 144);
$info: darken(#e33084, 10%);
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

$form-select-focus-border-color: $primary;

.form-select {
  background-color: $primary;
  border-color: $primary;

  option:hover {
    background-color: $primary;
  }

  option:active {
    background-color: $primary;
  }

  option:focus {
    background-color: $primary;
  }
}

select:hover {
  border-color: $primary;
}

select option:hover,
select option:focus {
  background-color: $primary;
  color: $white;
}

$theme-colors: (
  "primary": $primary,
  "secondary": rgb(28, 130, 144),
  "success": rgb(28, 130, 144),
  "info": darken(#e33084, 10%),
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
) !default;

$btn-link-color: #e33084;
$btn-link-hover-color: rgb(28, 130, 144);

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

.btn-primary:focus {
  background-color: rgb(28, 130, 144);
  border-color: rgb(28, 130, 144);
}




@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
        $value,
        $value,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount)
      );
    } @else if $color == "dark" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-tint-amount),
        $active-background: tint-color($value, $btn-active-bg-tint-amount),
        $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    } @else {
      @include button-variant($value, $value);
    }
    color: $white; 
    &.active,
    &:active {
      background-color: $primary;
      border-color: $primary;
      color: $white; 
    }
  }
}