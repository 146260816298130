.main-close2 {
  color: #ff0055;
  border: none;
  border-radius: 5px;
  background-color: white;
}

.main-close2:hover {
  color: white;
  background-color: #ff0055;
}

.main-close {
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 20px; /* Ajusta el padding para hacer el botón más grande */
  font-size: 12px; /* Ajusta el tamaño de fuente para hacer el texto más grande */
}

.main-close:hover {
  background-color: #51bed0;
}
