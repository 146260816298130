
.swal2-success-line-tip,
.swal2-success-line-long {
  font-size: 16px !important;
}

.swal2-x-mark-line-right,
.swal2-x-mark-line-left {
  font-size: 16px !important;
}

@media only screen and (max-height:480px) and (orientation:portrait) {
  .text-box {
    width: 200px;
    size: 5;
  }
}

.main-button {
  border-radius: 5px;
  background-color: #e33084;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 12px;
}

.main-button:hover {
  background-color: #d06aff;
}

.main-button:disabled {
  background-color: gray;
}

.active-workout {
  color: #d06aff;
}



background-color: #e33084;
opacity: 0.2;
background-image: radial-gradient(#1c8290 0.2px, #e33084e33084 0.2px);
background-size: 4px 4px;