.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Esto centra verticalmente en la mitad de la página */
}

.container-fluid h1 {
  font: bold;
  color: #e33084;
  font-size: 5rem;
}

.container-fluid p {
  font: bold;
  color: #51bed0;
  text-align: left;
}


.eliminarCitaBtn {
  display: block; /* Asegura que el botón se comporte como un bloque */
  margin: 0 auto; /* Establece los márgenes laterales a "auto" para centrarlo horizontalmente */
}

.backgroud {
  margin: 0;
  padding: 0;
  background-image: url("../../Images/Fondo.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 80vh;
}

.header {
  background-color: #e33084;
}

.icon {
  width: 1.5rem;
  height: 2rem;
  margin-right: 2rem;
}

.icon:hover {
  color: #51bed0;
}
